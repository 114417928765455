<template>
  <div>
    <v-container>
      <v-row dense no-gutters>
        <v-col cols="6" offset="3">
          <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
            <v-card>
              <v-card-text>
                <h2 class="text-h4 text-center mt-4">Account Sign-In</h2>
              </v-card-text>
              <v-card-text>

                <v-container fluid v-if="loading" class="mb-4">
                  <v-row dense>
                    <v-col class="mx-auto text-center">
                      <v-progress-circular
                        :size="70"
                        :width="7"
                        color="blue-grey darken-4"
                        indeterminate
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container fluid v-else>
                  <v-row dense>
                    <v-col cols="8" offset="2">
                      <v-text-field
                        outlined
                        type="email"
                        v-model="fields.email.value"
                        :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                        :label="fields.email.label"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="8" offset="2" class="text-end">
                      <v-text-field
                        outlined
                        type="password"
                        v-model="fields.password.value"
                        :rules="[$utilities.rules.required]"
                        :label="fields.password.label"
                      ></v-text-field>
                      <v-checkbox
                        v-model="fields.remember.value"
                        :label="fields.remember.label"
                        @change="saveEmailToCookie"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" offset="2">
                      <v-btn color="blue-grey darken-4" x-large dark @click="login">
                        Login
                      </v-btn>
                    </v-col>
                    <v-col cols="4" align="end">
                      <a href="#" @click="forgotDialog = true">
                        Forgot Password?
                      </a>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="my-3">
                    <v-col cols="5" class="text-center">
                      <v-divider />
                    </v-col>
                    <v-col cols="2" class="text-center"> OR </v-col>
                    <v-col cols="5" class="text-center">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="6" offset="3" align="center">
                      <div id="google-button"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              
            </v-card>  
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          Don't have an account? <router-link to="/register">Create one now</router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-modal-users-forgot v-model="forgotDialog"></v-modal-users-forgot>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { loadScript } from "vue-plugin-load-script";
  
  export default {
    name: 'Home.vue',
    data() {
      return {
        forgotDialog: false,
        loading: false,
        valid: false,
        fields: {
          email: {
            value: '',
            label: 'Email',
          },
          password: {
            value: '',
            label: 'Password',
          },
          remember: {
            value: false,
            label: 'Remember me',
          }
        },
      }
    },
    computed:{
      siteURL(){
        return process.env.VUE_APP_ENDPOINT;
      },
      googleClientID(){
        return process.env.VUE_APP_GOOGLE_CLIENT_ID;
      }
    },
    created(){
      let self = this;
      const storedEmail = this.$utilities.getCookie('userEmail');
      if (storedEmail) {
        this.fields.email.value = decodeURIComponent(storedEmail);
        this.fields.remember.value = true;
      }
      loadScript("https://accounts.google.com/gsi/client")
        .then(() => {
          if (!google) return; // eslint-disable-line
          google.accounts.id.initialize({ // eslint-disable-line
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: self.oauth
          });
          google.accounts.id.renderButton( // eslint-disable-line
            document.getElementById("google-button"),
            { theme: "outline", size: "large" }
          );
          google.accounts.id.prompt(); // eslint-disable-line
        })
        .catch(() => {
          document.getElementById("google-button").innerHTML = '<b>Please disable your Ad Blocker to enable Google Login!</b>'
        })
    },
    methods:{
      saveEmailToCookie() {
        const set = this.fields.remember.value;
        const email = this.fields.email.value;
        if (set && email) {
          this.$utilities.setCookie('userEmail', email, 30);
        }else{
          this.$utilities.deleteCookie('userEmail');
        }
      },
      ...mapActions({
          'userLogin':'users/login',
          'userOAuth':'users/oauth',
      }),
      oauth(response){
        this.loading = true;
        let self = this;
        let fields = {
          'credential': response.credential
        }
        this.userOAuth(fields)
          .then(() => {
            self.$router.push('/')
              .then(() => {
                self.systemMessage('Google account logged in successfully!');
                self.loading = false;
              })
          })
          .catch(() => {
            self.systemMessage('Account not found!')
            self.loading = false;
          })
      },
      login(){
        this.loading = true;
        let self = this;
        if(this.$refs.form.validate()){
          let fields = {
						'email': self.fields.email.value,
						'password': self.fields.password.value,
					}
          this.userLogin(fields)
						.then((response) => {
              if(!response.length > 0){
                setTimeout(() => {
                  self.$router.push('/')
                    .then(() => {
                      self.loading = false;
                    });
                }, 2000);
              }
            })
            .catch(() => {
              self.loading = false;
            })
        }
      },
    }
  }
</script>

<style lang="scss">
</style>